/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

h1, h2, h3, h4, h5, h6 {
    color: var(--ion-text-color);
}

.ohn-size-w-100 {
  width:100%;
}

.ohn-size-h-100 {
  height:100%;
}

.ohn-size-vw-100 {
    width: 100vw;
}

.ohn-size-vh-100 {
    height: 100vh;
}

.ohn-falign-hvc {
    justify-content: center;
    align-items: center;
}

.ohn-seal{
    width: clamp(300px, var(--ohn-seal-width), 500px);
    margin: 0 .5rem;
}

.ohn-logo {
  margin: 2rem auto;
}

.ohn-gradient {
/*
    &:before {
        display: block;
        content:"";
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80vw;
        height: 80vw;
        border-radius: 100%;
        background: rgb(7 190 185 / 80%);
        animation: breathe 7s linear infinite;
    }
*/
}

.ohn-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ohn-panel-content {
    height: 100%;
    overflow-y: scroll;
    width: clamp(300px,100%,680px);
    align-self: center;
  }

  &.ohn-falign-hvc {
    .ohn-panel-content {
        height: auto;
    }
  }
}

.ohn-sh-container {
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
 
    .ohn-sh-item {
        height:180px;
        width: 180px;
        min-width: 180px;
        border-radius: 16px;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        background: linear-gradient(45deg, #fff, #fff, rgb(255 255 255 / 80%));
        margin: 8px 8px 16px 16px ;
        padding: 2rem;
        font-weight: bold;
        display: -webkit-box; 
        -webkit-box-orient: vertical; 
        -webkit-line-clamp: 7; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    }
}

.ohn-video-player {
    text-align: center;

    .video {
        aspect-ratio: 16 / 9;
        width: 100%;
        background: #fff;
        border-radius: .5rem;
        margin: 1rem auto;
        padding: .2rem;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    }

    .controls {
        ion-range {
            margin: 0 0.5rem;
        }
        
    }
}

.ohn-audio-player {
    background: #fff;
    border-radius: 1.5rem;
    border: 4px solid #fff;
    margin: 1rem 0;
    padding: 4rem 0.5rem 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    /*background: linear-gradient(-45deg, #fef2f3, #3a95a9, #fef2e3, #3a95a9, #fef2f3);*/
    background: linear-gradient(-45deg, #fef2f3, rgb(254 242 243 / 70%), rgb(58 149 169 / 20%), #fef2e3, rgb(58 149 169 / 20%), rgb(254 242 243 / 70%), #fef2f3);

    background-size: 600% 100%;
    animation: gradient 16s linear infinite;
    animation-direction: alternate;

    h4 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 1rem;
    }

    audio {
        width: 100%;
    }

    .controls {
        ion-range {
            margin: 0 0.5rem;
        }
        
    }
}

@keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}

.ohn-embed-element {
    text-align: center;

    .pdf {
        aspect-ratio: 1 / 1.41;
        width: 100%;
        height: 100%;
        margin: 1rem auto;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    } 
}

ion-content::part(scroll) {
    padding-top: var(--ion-safe-area-top, 0);
}



ion-tab-bar {
    --border: 0;
}

ion-tab-button {
    --color: var(--ion-color-primary);
    --color-selected: var(--ion-color-secondary);

    ion-icon {
        font-size: 30px;
    }
}

h2 {
    font-size: 2rem;
}

p {
    font-size: 1.5rem;
}

app-branched-content-renderer {
    height: 100%;
    width: 100%;

    .ohn-panel-footer {
        padding-bottom: var(--ion-safe-area-bottom, 0);
    }
}

ion-alert {
    &.ohn-alert {
        .alert-radio-label {
            color: var(--ion-text-color);
        }

        [aria-checked=true] .alert-radio-label {
            color: var(--ion-color-primary) !important;
        }
    }
}

ion-text {
    color: var(--ion-text-color);
}

ion-toast {
    &.ohn-toast {
      --background: rgba(var(--ion-color-secondary-rgb), .8);
      --box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
      &::part(message) {
        font-size:20px;
        font-weight: bold;
      }
    }
}

ion-radio,
ion-checkbox {
    --color-checked: var(--ion-color-secondary);
}

.ohn-masked-overflow-x {
  -webkit-mask-image: linear-gradient(to left, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
  mask-image: linear-gradient(to left, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
  
}

.ohn-masked-overflow-y {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
  padding-top: 2rem;
  
}

/* PAGES SPEC */

.ohn-login-form {
    ion-item {
        margin: 1rem auto;
      --background: var(--ion-color-primary);
      --color: #fff;
      --border-radius: 2rem;
      --ripple-color: var(--ion-color-primary-shade);

      ion-select {
            font-weight: bold;
            &::part(icon) {
                color: var(--ion-color-light);
            }
        }
    }

    ion-input {
        --border-width: 2px;
    }
}

app-branched-content-renderer {
    .ohn-panel-content {
        /*-webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
        mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);*/
    }

    .ohn-panel-header,
    .ohn-panel-footer {
        box-shadow: 0 0 2px rgb(86 80 111 / 20%);
    }
}

.home {
    --background: linear-gradient(to bottom, #f6f8fc, #fff);
    --padding-top:1rem;

    .today {
        padding: 8px;
        cursor: pointer;
        border: 1px solid #ECEFF1;
        border-radius: 1rem;
        box-shadow: 0 2px 8px rgba(207, 216, 220, 0.25);
        margin: 0;
        height:100%;
        span {
            display: block;

            &.today-e {
                text-transform: uppercase;
                margin-top: 1rem;
            }

            &.today-d {
                font-size: 4rem;
            }
        }
    }

    .widget {
        cursor: pointer;
        border: 1px solid #ECEFF1;
        border-radius: 1rem;
        box-shadow: 0 2px 8px rgba(207, 216, 220, 0.25);
        margin: 0;
        height:100%;
    }

    ion-list {
        background: none;
    }

    .event {
        cursor: pointer;
        border: 1px solid #ECEFF1;
        box-shadow: none;

        ion-thumbnail {
          --size: 42px;
        }
    }
}


/* INSPIRE SPEC */
.chart-1 {
    ion-card {
        margin: 0.5rem 1rem;
    }
}

markdown {
    blockquote {
        background: rgb(255 0 0 / 10%);
        margin: 0;
        padding: 0.5rem 2rem;
        border-radius: 1rem;
    }
}

app-image-element {
    img {
        border-radius: 8px;
    }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
    .ohn-video-player {
        .video {
            width: auto;
        }
    }

    .ohn-embed-element {
        .pdf {
            width: auto;
        } 
    }
}